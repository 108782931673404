// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery.cookie")
import "@fortawesome/fontawesome-free/js/all";
import 'lity/dist/lity.min.js';
import 'bootstrap-select';
import 'swiper/dist/js/swiper.js';
import 'controllers';
import 'select2';
import Rails from '@rails/ujs';

import 'bootstrap-slider';
import  'clipboard';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap/dist/js/bootstrap';

import $ from 'jquery';
import Lity from 'lity';
// import Clipboard from 'clipboard/src/clipboard';

// import 'select2';
// import 'select2/dist/css/select2.css';
 // import "select2/dist/js/i18n/de.js";

// $(document).ready(function() {
//     $('select').select2();
// });

global.Rails = Rails;

$(document).on('turbolinks:load', function() {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });



    // ------------------------------------------------------- //
    // Search Popup
    // ------------------------------------------------------ //
    $('a.search-btn').on('click', function () {
      $('.search').fadeIn();
  });
  $('.search .close-btn').on('click', function () {
      $('.search').fadeOut();
  });



  // ------------------------------------------------------- //
  // Bootstrap Select initialization
  // ------------------------------------------------------ //
  $('.selectpicker').selectpicker({
      size: 4
  });


  // ------------------------------------------------------- //
  // Adding fade effect to dropdowns
  // ------------------------------------------------------ //
  $('.dropdown').on('show.bs.dropdown', function () {
      $(this).find('.dropdown-menu').first().stop(true, true).fadeIn(100).addClass('active');
  });
  $('.dropdown').on('hide.bs.dropdown', function () {
      $(this).find('.dropdown-menu').first().stop(true, true).fadeOut(100).removeClass('active');
  });

  $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
      event.preventDefault();
      event.stopPropagation();

      $(this).siblings().toggleClass("show");


      if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
      }
      $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
          $('.dropdown-submenu .show').removeClass("show");
      });

  });


  // ------------------------------------------------------- //
  // Navbar Toggler Button
  // ------------------------------------------------------- //
  $('.navbar-toggler').on('click', function () {
      $(this).toggleClass('active');
  });


  // ------------------------------------------------------- //
  // Hero Slider
  // ------------------------------------------------------ //
  var swiper = new Swiper('.hero-slider', {
          slidesPerView: 1,
          spaceBetween: 0,
          speed: 600,
          autoplay: {
              delay: 10000,
          },
          pagination: {
              el: '.swiper-pagination',
              clickable: true
          }
      });


  // ------------------------------------------------------- //
  // Events Slider
  // ------------------------------------------------------ //
  var swiper = new Swiper('.events-slider', {
          slidesPerView: 2,
          spaceBetween: 30,
          breakpoints: {
             991: {
                 slidesPerView: 1
             }
         },
          pagination: {
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: true
          }
      });


  // ------------------------------------------------------- //
  // Testimonials Slider
  // ------------------------------------------------------ //
  var swiper = new Swiper('.testimonials-slider', {
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: {
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: true
          }
      });

// ------------------------------------------------------- //
  // Google Maps
  // ------------------------------------------------------ //
  if ($('#map').length > 0) {


      function initMap() {

          var location = new google.maps.LatLng(50.0875726, 14.4189987);

          var mapCanvas = document.getElementById('map');
          var mapOptions = {
              center: location,
              zoom: 16,
              panControl: false,
              mapTypeId: google.maps.MapTypeId.ROADMAP
          }
          var map = new google.maps.Map(mapCanvas, mapOptions);

          var markerImage = 'img/marker.png';

          var marker = new google.maps.Marker({
              position: location,
              map: map,
              icon: markerImage
          });

          var contentString = '<div class="info-window">' +
              '<h3>Info Window Content</h3>' +
              '<div class="info-content">' +
              '<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>' +
              '</div>' +
              '</div>';

          var infowindow = new google.maps.InfoWindow({
              content: contentString,
              maxWidth: 400
          });

          marker.addListener('click', function () {
              infowindow.open(map, marker);
          });

          var styles = [{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 65 }, { "visibility": "on" }] }, { "featureType": "poi", "stylers": [{ "saturation": -100 }, { "lightness": 51 }, { "visibility": "off" }] }, { "featureType": "road.highway", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "road.arterial", "stylers": [{ "saturation": -100 }, { "lightness": 30 }, { "visibility": "on" }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "labels", "stylers": [{ "visibility": "on" }, { "lightness": -25 }, { "saturation": -100 }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "hue": "#ffff00" }, { "lightness": -25 }, { "saturation": -97 }] }];

          map.set('styles', styles);


      }

      google.maps.event.addDomListener(window, 'load', initMap);


  }

});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});
